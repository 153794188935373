import "./src/styles/components.css";
import "./global.css";
import "normalize.css";
import "react-notifications-component/dist/theme.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";

import { StylesProvider } from "@material-ui/core/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactDOM from "react-dom";

import MainLayout from "./src/components/Containers/Layout";
import GlobalStyles from "./src/components/GlobalStyles";
import "moment/locale/es";

const queryClient = new QueryClient();

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback);
  };
}

export const wrapRootElement = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <StylesProvider injectFirst>
        <GlobalStyles />
        <MainLayout>{props.element}</MainLayout>
      </StylesProvider>
    </QueryClientProvider>
  );
};
