// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-appointment-[id]-assisted-schedule-tsx": () => import("./../../../src/pages/appointment/[id]/assisted-schedule.tsx" /* webpackChunkName: "component---src-pages-appointment-[id]-assisted-schedule-tsx" */),
  "component---src-pages-appointment-[id]-index-tsx": () => import("./../../../src/pages/appointment/[id]/index.tsx" /* webpackChunkName: "component---src-pages-appointment-[id]-index-tsx" */),
  "component---src-pages-appointment-[id]-operative-edit-tsx": () => import("./../../../src/pages/appointment/[id]/operative-edit.tsx" /* webpackChunkName: "component---src-pages-appointment-[id]-operative-edit-tsx" */),
  "component---src-pages-appointment-[id]-reschedule-tsx": () => import("./../../../src/pages/appointment/[id]/reschedule.tsx" /* webpackChunkName: "component---src-pages-appointment-[id]-reschedule-tsx" */),
  "component---src-pages-capacity-tsx": () => import("./../../../src/pages/capacity.tsx" /* webpackChunkName: "component---src-pages-capacity-tsx" */),
  "component---src-pages-checkout-builder-[id]-index-tsx": () => import("./../../../src/pages/checkout-builder/[id]/index.tsx" /* webpackChunkName: "component---src-pages-checkout-builder-[id]-index-tsx" */),
  "component---src-pages-checkout-builder-index-tsx": () => import("./../../../src/pages/checkout-builder/index.tsx" /* webpackChunkName: "component---src-pages-checkout-builder-index-tsx" */),
  "component---src-pages-coupons-[id]-edit-tsx": () => import("./../../../src/pages/coupons/[id]/edit.tsx" /* webpackChunkName: "component---src-pages-coupons-[id]-edit-tsx" */),
  "component---src-pages-coupons-[id]-index-tsx": () => import("./../../../src/pages/coupons/[id]/index.tsx" /* webpackChunkName: "component---src-pages-coupons-[id]-index-tsx" */),
  "component---src-pages-coupons-create-tsx": () => import("./../../../src/pages/coupons/create.tsx" /* webpackChunkName: "component---src-pages-coupons-create-tsx" */),
  "component---src-pages-coupons-index-tsx": () => import("./../../../src/pages/coupons/index.tsx" /* webpackChunkName: "component---src-pages-coupons-index-tsx" */),
  "component---src-pages-dashboard-[id]-totalpack-masive-downloader-tsx": () => import("./../../../src/pages/dashboard/[id]/totalpack-masive-downloader.tsx" /* webpackChunkName: "component---src-pages-dashboard-[id]-totalpack-masive-downloader-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-dashboard-totalpack-tsx": () => import("./../../../src/pages/dashboard/totalpack.tsx" /* webpackChunkName: "component---src-pages-dashboard-totalpack-tsx" */),
  "component---src-pages-health-team-[id]-block-schedule-tsx": () => import("./../../../src/pages/health-team/[id]/block-schedule.tsx" /* webpackChunkName: "component---src-pages-health-team-[id]-block-schedule-tsx" */),
  "component---src-pages-health-team-[id]-exams-tsx": () => import("./../../../src/pages/health-team/[id]/exams.tsx" /* webpackChunkName: "component---src-pages-health-team-[id]-exams-tsx" */),
  "component---src-pages-health-team-[id]-index-tsx": () => import("./../../../src/pages/health-team/[id]/index.tsx" /* webpackChunkName: "component---src-pages-health-team-[id]-index-tsx" */),
  "component---src-pages-health-team-[id]-schedule-tsx": () => import("./../../../src/pages/health-team/[id]/schedule.tsx" /* webpackChunkName: "component---src-pages-health-team-[id]-schedule-tsx" */),
  "component---src-pages-health-team-announcements-tsx": () => import("./../../../src/pages/health-team/announcements.tsx" /* webpackChunkName: "component---src-pages-health-team-announcements-tsx" */),
  "component---src-pages-health-team-index-tsx": () => import("./../../../src/pages/health-team/index.tsx" /* webpackChunkName: "component---src-pages-health-team-index-tsx" */),
  "component---src-pages-health-team-new-tsx": () => import("./../../../src/pages/health-team/new.tsx" /* webpackChunkName: "component---src-pages-health-team-new-tsx" */),
  "component---src-pages-health-team-tracking-tsx": () => import("./../../../src/pages/health-team/tracking.tsx" /* webpackChunkName: "component---src-pages-health-team-tracking-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-laboratory-[id]-index-tsx": () => import("./../../../src/pages/laboratory/[id]/index.tsx" /* webpackChunkName: "component---src-pages-laboratory-[id]-index-tsx" */),
  "component---src-pages-laboratory-index-tsx": () => import("./../../../src/pages/laboratory/index.tsx" /* webpackChunkName: "component---src-pages-laboratory-index-tsx" */),
  "component---src-pages-labservice-[id]-schedule-tsx": () => import("./../../../src/pages/labservice/[id]/schedule.tsx" /* webpackChunkName: "component---src-pages-labservice-[id]-schedule-tsx" */),
  "component---src-pages-massive-upload-tsx": () => import("./../../../src/pages/massive_upload.tsx" /* webpackChunkName: "component---src-pages-massive-upload-tsx" */),
  "component---src-pages-medical-orders-[id]-index-tsx": () => import("./../../../src/pages/medical-orders/[id]/index.tsx" /* webpackChunkName: "component---src-pages-medical-orders-[id]-index-tsx" */),
  "component---src-pages-medical-orders-index-tsx": () => import("./../../../src/pages/medical-orders/index.tsx" /* webpackChunkName: "component---src-pages-medical-orders-index-tsx" */),
  "component---src-pages-new-packs-index-tsx": () => import("./../../../src/pages/new-packs/index.tsx" /* webpackChunkName: "component---src-pages-new-packs-index-tsx" */),
  "component---src-pages-new-packs-new-tsx": () => import("./../../../src/pages/new-packs/new.tsx" /* webpackChunkName: "component---src-pages-new-packs-new-tsx" */),
  "component---src-pages-nurse-payments-index-tsx": () => import("./../../../src/pages/nurse-payments/index.tsx" /* webpackChunkName: "component---src-pages-nurse-payments-index-tsx" */),
  "component---src-pages-offline-appointment-assisted-schedule-tsx": () => import("./../../../src/pages/offline-appointment/assisted-schedule.tsx" /* webpackChunkName: "component---src-pages-offline-appointment-assisted-schedule-tsx" */),
  "component---src-pages-offline-appointment-create-tsx": () => import("./../../../src/pages/offline-appointment/create.tsx" /* webpackChunkName: "component---src-pages-offline-appointment-create-tsx" */),
  "component---src-pages-offline-appointment-mx-create-tsx": () => import("./../../../src/pages/offline-appointment-mx/create.tsx" /* webpackChunkName: "component---src-pages-offline-appointment-mx-create-tsx" */),
  "component---src-pages-packs-[id]-edit-tsx": () => import("./../../../src/pages/packs/[id]/edit.tsx" /* webpackChunkName: "component---src-pages-packs-[id]-edit-tsx" */),
  "component---src-pages-packs-[id]-index-tsx": () => import("./../../../src/pages/packs/[id]/index.tsx" /* webpackChunkName: "component---src-pages-packs-[id]-index-tsx" */),
  "component---src-pages-packs-index-tsx": () => import("./../../../src/pages/packs/index.tsx" /* webpackChunkName: "component---src-pages-packs-index-tsx" */),
  "component---src-pages-packs-new-tsx": () => import("./../../../src/pages/packs/new.tsx" /* webpackChunkName: "component---src-pages-packs-new-tsx" */),
  "component---src-pages-patients-[id]-edit-tsx": () => import("./../../../src/pages/patients/[id]/edit.tsx" /* webpackChunkName: "component---src-pages-patients-[id]-edit-tsx" */),
  "component---src-pages-patients-[id]-index-tsx": () => import("./../../../src/pages/patients/[id]/index.tsx" /* webpackChunkName: "component---src-pages-patients-[id]-index-tsx" */),
  "component---src-pages-patients-index-tsx": () => import("./../../../src/pages/patients/index.tsx" /* webpackChunkName: "component---src-pages-patients-index-tsx" */),
  "component---src-pages-pending-[id]-index-tsx": () => import("./../../../src/pages/pending/[id]/index.tsx" /* webpackChunkName: "component---src-pages-pending-[id]-index-tsx" */),
  "component---src-pages-pending-index-tsx": () => import("./../../../src/pages/pending/index.tsx" /* webpackChunkName: "component---src-pages-pending-index-tsx" */),
  "component---src-pages-refunds-tsx": () => import("./../../../src/pages/refunds.tsx" /* webpackChunkName: "component---src-pages-refunds-tsx" */),
  "component---src-pages-reports-index-tsx": () => import("./../../../src/pages/reports/index.tsx" /* webpackChunkName: "component---src-pages-reports-index-tsx" */),
  "component---src-pages-reviewed-[id]-tsx": () => import("./../../../src/pages/reviewed/[id].tsx" /* webpackChunkName: "component---src-pages-reviewed-[id]-tsx" */),
  "component---src-pages-sectors-index-tsx": () => import("./../../../src/pages/sectors/index.tsx" /* webpackChunkName: "component---src-pages-sectors-index-tsx" */),
  "component---src-pages-subscriptions-[id]-add-service-tsx": () => import("./../../../src/pages/subscriptions/[id]/add-service.tsx" /* webpackChunkName: "component---src-pages-subscriptions-[id]-add-service-tsx" */),
  "component---src-pages-subscriptions-[id]-index-tsx": () => import("./../../../src/pages/subscriptions/[id]/index.tsx" /* webpackChunkName: "component---src-pages-subscriptions-[id]-index-tsx" */),
  "component---src-pages-subscriptions-index-tsx": () => import("./../../../src/pages/subscriptions/index.tsx" /* webpackChunkName: "component---src-pages-subscriptions-index-tsx" */),
  "component---src-pages-users-[id]-edit-tsx": () => import("./../../../src/pages/users/[id]/edit.tsx" /* webpackChunkName: "component---src-pages-users-[id]-edit-tsx" */),
  "component---src-pages-users-[id]-index-tsx": () => import("./../../../src/pages/users/[id]/index.tsx" /* webpackChunkName: "component---src-pages-users-[id]-index-tsx" */),
  "component---src-pages-users-index-tsx": () => import("./../../../src/pages/users/index.tsx" /* webpackChunkName: "component---src-pages-users-index-tsx" */)
}

